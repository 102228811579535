import React, { Component } from 'react';


class Aboutme extends Component{
    render() {
        return(
            <div><h1>About me</h1></div>
        )
    }
}

export default Aboutme;